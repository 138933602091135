<template>
  <component :is="userData === undefined ? 'div' : 'b-card'">
    <b-tabs v-if="userData" pills>
      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Personal Info</span>
        </template>
        <!-- <PersonalInfo class="mt-2 pt-75" /> -->
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="SlackIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Address</span>
        </template>
        <!-- <Address class="mt-2 pt-75" /> -->
      </b-tab>

      <!-- Tab: Account Information -->
      <b-tab>
        <template #title>
          <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
          <span @click="changeTab('account')" class="d-none d-sm-inline"
            >Account Information</span
          >
        </template>
        <!-- <AccoutInfo class="mt-2 pt-75" /> -->
      </b-tab>
      <!-- Tab: Billing/Payment Information -->
      <b-tab>
        <template #title>
          <feather-icon icon="SettingsIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Billing/Payment Information</span>
        </template>
        <!-- <BillingInfos class="mt-2 pt-75" /> -->
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="ToolIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Connection</span>
        </template>
        <!-- <UserEditConnectionTab class="mt-2 pt-75" /> -->
      </b-tab>
    </b-tabs>
    <b-row>
      <b-col class="mt-2">
        <b-button
          @click="save_user()"
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        >
          Save Changes
        </b-button>
      </b-col>
    </b-row>
  </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import userStoreModule from "../userStoreModule.js";
import UserEditTabAccount from "./UserEditTabAccount.vue";
import UserEditTabInformation from "./UserEditTabInformation.vue";
import PersonalInfo from "./PersonalInfo.vue";
import Address from "./Address.vue";
import AccoutInfo from "./AccoutInfo.vue";
import BillingInfos from "./BillingInfos.vue";
import UserEditConnectionTab from "./UserEditConnectionTab.vue";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    AccoutInfo,
    PersonalInfo,
    Address,
    UserEditTabAccount,
    UserEditTabInformation,
    BillingInfos,
    UserEditConnectionTab,
  },
  data() {
    return {
      nas_type: "", //MikroTik Radius MikroTik+Radius
      user_types: [
        { type: "Home User", value: "Home" },
        { type: "Corporate User", value: "Corporate" },
      ],

      //= ====for data edit
      editData: {
        mode: false,
        id: null,
      },
      loading: true,
      modal_title: "Create Data",
      step: {
        one: false,
        second: true,
      },
      district_select_options: {
        options: [],
      },
      selected_distric: {
        code: "",
        label: "-------Please Select One-----------",
      },
      sameAddress: false,
      bank_payment: 0,
      upazila_one: [],
      upazila_two: [],
    };
  },
  computed: {
    userData() {
      console.log(
        "this.$store.state.isp.customer_edit_data",
        this.$store.state.isp.customer_edit_data
      );
      return this.$store.state.isp.customer_edit_data;
    },
  },
  created() {
    console.log("this.$route.params", this.$route.params);
    this.$store.dispatch("others", {
      zone: true,
      mikrotik: true,
      user_reference_number: true,
      package: true,
      districts: true,
    });
    this.$store.dispatch("customer_edit", this.$route.params);
  },
};
</script>
