<template>
  <div>
    <!-- Media -->
    <b-media class="mb-2">
      <template #aside>
        <b-avatar
          ref="previewEl"
          :src="userData.avatar"
          variant="light"
          size="90px"
          rounded
        />
      </template>
      <h4 class="mb-1">
        {{ userData.name }}
      </h4>
      <div class="d-flex flex-wrap">
        <b-button variant="primary" @click="$refs.refInputEl.click()">
          <input ref="refInputEl" type="file" class="d-none" />
          <span class="d-none d-sm-inline">Update</span>
          <feather-icon icon="EditIcon" class="d-inline d-sm-none" />
        </b-button>
        <b-button variant="outline-secondary" class="ml-1">
          <span class="d-none d-sm-inline">Remove</span>
          <feather-icon icon="TrashIcon" class="d-inline d-sm-none" />
        </b-button>
      </div>
    </b-media>

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>
        <!-- Field: Username -->
        <b-col cols="12" md="4">
          <b-form-group label="Select User Type" label-for="username">
            <select
              v-model="userData.type"
              name="user_type"
              class="form-control"
            >
              <option value="" selected disabled>
                ------Please Select One -----
              </option>
              <option value="Home">Home</option>
              <option value="Corporate">Corporate</option>
            </select>
          </b-form-group>
        </b-col>
        <!-- Field: Username -->
        <b-col cols="12" md="4">
          <b-form-group label="Username" label-for="username">
            <b-form-input
              id="username"
              v-model="userData.connection_info.username"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Full Name -->
        <b-col cols="12" md="4">
          <b-form-group label="Name" label-for="full-name">
            <b-form-input id="full-name" v-model="userData.name" />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col cols="12" md="4">
          <b-form-group label="Email" label-for="email">
            <b-form-input id="email" v-model="userData.email" type="email" />
          </b-form-group>
        </b-col>
        <!-- Field: Email -->
        <b-col cols="12" md="4">
          <b-form-group label="phone" label-for="phone">
            <b-form-input id="phone" v-model="userData.phone" type="phone" />
          </b-form-group>
        </b-col>
        <!-- Field: father_name -->
        <b-col cols="12" md="4">
          <b-form-group label="Father's Name" label-for="father_name">
            <b-form-input
              id="father_name"
              v-model="userData.userdetails.father_name"
            />
          </b-form-group>
        </b-col>
        <!-- Field:Mother's Name * -->
        <b-col cols="12" md="4">
          <b-form-group label="Mother's Name *" label-for="mother_name">
            <b-form-input
              id="mother_name"
              v-model="userData.userdetails.mother_name"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Gender -->
        <b-col cols="12" md="6" lg="4">
          <b-form-group label="Gender" label-for="gender" label-class="mb-1">
            <b-form-radio-group
              id="gender"
              v-model="userData.userdetails.gender"
              :options="genderOptions"
              value="male"
            />
          </b-form-group>
        </b-col>
        <!-- Field: gender -->
        <b-col cols="12" md="4">
          <b-form-group label="Proof Of Identity" label-for="gender">
            <select
              v-model="userData.userdetails.identification_reference"
              class="form-control"
              name="prof-of-identity"
            >
              <option value="">---select one----</option>
              <option value="NID">NID</option>
              <option value="Birthday-Certificate">Birthday Certifcate</option>
              <option value="Passport">Passport</option>
            </select>
          </b-form-group>

          <b-form-group
            v-if="userData.identification_reference"
            :label="`Please Enter ${userData.identification_reference} Number`"
            label-for="gender"
          >
            <input
              v-model="userData.identification_reference_number"
              type="text"
              class="form-control"
              name="number"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col cols="12" md="4">
          <b-form-group label="Occupation" label-for="Occupation">
            <b-form-input
              id="Occupation"
              v-model="userData.userdetails.occupation"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="4">
          <b-form-group label="Birthday Date" label-for="Birthday Date">
            <b-form-input
              id="Occupation"
              v-model="userData.userdetails.birthday_date"
              type="date"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
  },
  data() {
    return {
      genderOptions: [
        { text: "Male", value: "male" },
        { text: "Female", value: "female" },
      ],
    };
  },
  computed: {
    userData() {
      return this.$store.state.isp.customer_edit_data;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
