var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('label',{attrs:{"for":""}},[_vm._v("Connection Type: "),_c('sup',{staticClass:"text-danger"},[_vm._v("*")])]),_vm._v(" "),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.userData.billing_info.connection_type),expression:"userData.billing_info.connection_type"}],staticClass:"form-control",attrs:{"name":"connection_type"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.userData.billing_info, "connection_type", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.change_con_type()}]}},[_c('option',{attrs:{"value":"","selected":"","disabled":""}},[_vm._v("---Select Type--")]),_vm._v(" "),_c('option',{directives:[{name:"show",rawName:"v-show",value:(!_vm.is_queue),expression:"!is_queue"}],attrs:{"value":"di_private"}},[_vm._v("\n          Dynamic IP Private (PPPOE NAT)\n        ")]),_vm._v(" "),_c('option',{directives:[{name:"show",rawName:"v-show",value:(!_vm.is_queue),expression:"!is_queue"}],attrs:{"value":"di_public"}},[_vm._v("\n          Dynamic IP Public(PPPOE)\n        ")]),_vm._v(" "),_c('option',{directives:[{name:"show",rawName:"v-show",value:(!_vm.is_queue),expression:"!is_queue"}],attrs:{"value":"si_private"}},[_vm._v("\n          Static IP Private (PPPOE)\n        ")]),_vm._v(" "),_c('option',{directives:[{name:"show",rawName:"v-show",value:(!_vm.is_queue),expression:"!is_queue"}],attrs:{"value":"si_public"}},[_vm._v("\n          Static IP Public (PPPOE)\n        ")]),_vm._v(" "),(
            _vm.userData.billing_info.connection_type == 'si_private_queue' ||
            _vm.userData.billing_info.connection_type == 'si_public_queue' ||
            _vm.userData.billing_info.connection_type == 'si_m_b'
          )?_c('option',{attrs:{"value":"si_private_queue"}},[_vm._v("\n          Static IP Private (Queue)\n        ")]):_vm._e(),_vm._v(" "),(
            _vm.userData.billing_info.connection_type == 'si_private_queue' ||
            _vm.userData.billing_info.connection_type == 'si_public_queue' ||
            _vm.userData.billing_info.connection_type == 'si_m_b'
          )?_c('option',{attrs:{"value":"si_public_queue"}},[_vm._v("\n          Static IP Public (Queue)\n        ")]):_vm._e(),_vm._v(" "),(
            _vm.userData.billing_info.connection_type == 'si_private_queue' ||
            _vm.userData.billing_info.connection_type == 'si_public_queue' ||
            _vm.userData.billing_info.connection_type == 'si_m_b'
          )?_c('option',{attrs:{"value":"si_m_b"}},[_vm._v("\n          Static IP MAC Bind (ARP)\n        ")]):_vm._e()])]),_vm._v(" "),_c('div',{staticClass:"col-lg-6"},[(_vm.nas_type == 'MikroTik')?_c('div',[_c('label',{attrs:{"for":""}},[_vm._v("MikroTik "),_c('sup',{staticClass:"text-danger"},[_vm._v("*")])]),_vm._v(" "),(_vm.mikrotiks)?_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.userData.connection_info.mikrotik_id),expression:"userData.connection_info.mikrotik_id"}],staticClass:"form-control",attrs:{"disabled":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.userData.connection_info, "mikrotik_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("---------Please Select One -------")]),_vm._v(" "),_vm._l((_vm.mikrotiks),function(mikrotik,midx){return _c('option',{key:midx,domProps:{"value":mikrotik.id}},[_vm._v("\n            "+_vm._s(mikrotik.identity + "-" + mikrotik.host)+"\n          ")])})],2):_vm._e()]):_vm._e()])]),_vm._v(" "),(
      _vm.userData.billing_info.connection_type == 'di_private' ||
      _vm.userData.billing_info.connection_type == 'di_public' ||
      _vm.userData.billing_info.connection_type == 'si_private' ||
      _vm.userData.billing_info.connection_type == 'si_public'
    )?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 mt-1"},[_c('label',{attrs:{"for":""}},[_vm._v("User Name "),_c('sup',{staticClass:"text-danger"},[_vm._v("*")])]),_vm._v(" "),_c('div',{staticClass:"from-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userData.connection_info.username),expression:"userData.connection_info.username"}],staticClass:"form-control",attrs:{"disabled":"","type":"text","placeholder":"User Name"},domProps:{"value":(_vm.userData.connection_info.username)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userData.connection_info, "username", $event.target.value)}}})])]),_vm._v(" "),_c('div',{staticClass:"col-lg-6 mt-1"},[_c('label',{attrs:{"for":""}},[_vm._v("User Password "),_c('sup',{staticClass:"text-danger"},[_vm._v("*")])]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userData.connection_info.user_password),expression:"userData.connection_info.user_password"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"User Password"},domProps:{"value":(_vm.userData.connection_info.user_password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userData.connection_info, "user_password", $event.target.value)}}})])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"row"},[(
        _vm.userData.billing_info.connection_type == 'di_private' ||
        _vm.userData.billing_info.connection_type == 'di_public' ||
        _vm.userData.billing_info.connection_type == 'si_private' ||
        _vm.userData.billing_info.connection_type == 'si_public'
      )?_c('div',{staticClass:"col-lg-6 mt-1"},[_c('label',{attrs:{"for":"Service"}},[_vm._v("Service")]),_vm._v(" "),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.userData.connection_info.service),expression:"userData.connection_info.service"}],staticClass:"form-control",attrs:{"disabled":"","id":"Service"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.userData.connection_info, "service", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"oVPN"}},[_vm._v("oVPN")]),_vm._v(" "),_c('option',{attrs:{"value":"PPPoE"}},[_vm._v("PPPoE")]),_vm._v(" "),_c('option',{attrs:{"value":"PPtP"}},[_vm._v("PPtP")])])]):_vm._e(),_vm._v(" "),(
        _vm.userData.billing_info.connection_type == 'si_public' ||
        _vm.userData.billing_info.connection_type == 'si_private'
      )?_c('div',{staticClass:"col-lg-6 my-1"},[_c('label',{attrs:{"for":""}},[_vm._v("Remote Address")]),_vm._v(" "),_c('div',{staticClass:"d-flex"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userData.connection_info.remote_address),expression:"userData.connection_info.remote_address"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Remote Address"},domProps:{"value":(_vm.userData.connection_info.remote_address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userData.connection_info, "remote_address", $event.target.value)}}}),_vm._v(" "),_c('button',{staticClass:"btn btn-primary btn-sm",on:{"click":function($event){$event.preventDefault();return _vm.see_remote_address()}}},[_c('feather-icon',{attrs:{"icon":"EyeIcon","size":"16"}})],1)])]):_vm._e(),_vm._v(" "),(
        _vm.userData.billing_info.connection_type == 'si_private_queue' ||
        _vm.userData.billing_info.connection_type == 'si_m_b' ||
        _vm.userData.billing_info.connection_type == 'si_public_queue'
      )?_c('div',{staticClass:"col-lg-6"},[_c('label',{staticClass:"mt-1 mr-2",attrs:{"for":"queues"}},[_vm._v("Queues")]),_vm._v(" "),_c('div',{staticClass:"d-flex"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.userData.connection_info.queue_name),expression:"userData.connection_info.queue_name"}],staticClass:"form-control",attrs:{"id":""},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.userData.connection_info, "queue_name", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},_vm.change_queue_name]}},[_c('option',{attrs:{"value":""}},[_vm._v("---Select--")]),_vm._v(" "),_vm._l((_vm.queue_data),function(queue_item){return _c('option',{key:queue_item.id,domProps:{"value":queue_item.name}},[_vm._v("\n            "+_vm._s(queue_item.name)+"\n          ")])})],2),_vm._v(" "),_c('button',{staticClass:"btn btn-primary btn-sm",on:{"click":function($event){$event.preventDefault();return _vm.modelAction('add_simple_queue')}}},[_c('feather-icon',{attrs:{"icon":"PlusCircleIcon","size":"16"}})],1)])]):_vm._e(),_vm._v(" "),(
        _vm.userData.billing_info.connection_type == 'si_private_queue' ||
        _vm.userData.billing_info.connection_type == 'si_m_b' ||
        _vm.userData.billing_info.connection_type == 'si_public_queue'
      )?_c('div',{staticClass:"col-lg-6 my-1"},[_c('label',{attrs:{"for":"target_address"}},[_vm._v("Target Address")]),_vm._v(" "),_c('div',{staticClass:"d-flex"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userData.connection_info.target_address),expression:"userData.connection_info.target_address"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Ex: 192.168.0.1","id":"target_address"},domProps:{"value":(_vm.userData.connection_info.target_address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userData.connection_info, "target_address", $event.target.value)}}}),_vm._v(" "),_c('button',{staticClass:"btn btn-primary btn-sm",on:{"click":function($event){$event.preventDefault();return _vm.modelAction('target_address')}}},[_c('feather-icon',{attrs:{"icon":"EyeIcon","size":"16"}})],1)])]):_vm._e(),_vm._v(" "),(
        _vm.userData.billing_info.connection_type == 'si_private_queue' ||
        _vm.userData.billing_info.connection_type == 'si_m_b' ||
        _vm.userData.billing_info.connection_type == 'si_public_queue'
      )?_c('div',{staticClass:"col-lg-6 my-1"},[_c('label',{attrs:{"for":"destination"}},[_vm._v("Destination (Interfaces)")]),_vm._v(" "),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.userData.connection_info.destination),expression:"userData.connection_info.destination"}],staticClass:"form-control",attrs:{"id":"destination"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.userData.connection_info, "destination", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("---Select Destination (Interfaces)----")]),_vm._v(" "),_vm._l((_vm.interfaces),function(inf){return _c('option',{key:inf.id,domProps:{"value":inf.name}},[_vm._v("\n          "+_vm._s(inf.name)+"\n        ")])})],2)]):_vm._e(),_vm._v(" "),(
        _vm.userData.billing_info.connection_type == 'si_private_queue' ||
        _vm.userData.billing_info.connection_type == 'si_m_b' ||
        _vm.userData.billing_info.connection_type == 'si_public_queue'
      )?_c('div',{staticClass:"col-lg-6 my-1"},[_c('label',{attrs:{"for":"queue_type"}},[_vm._v("Queue Type (PCQ)")]),_vm._v(" "),_c('div',{staticClass:"d-flex"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.userData.connection_info.queue),expression:"userData.connection_info.queue"}],staticClass:"form-control",attrs:{"disabled":"","id":"queue_type"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.userData.connection_info, "queue", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("---Select Queue Type----")]),_vm._v(" "),_vm._l((_vm.queue_types),function(q_t){return _c('option',{key:q_t.id,domProps:{"value":((q_t.name) + "/" + (q_t.name))}},[_vm._v("\n            "+_vm._s(q_t.name)+"\n          ")])})],2),_vm._v(" "),_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal",value:('add_queue-modal'),expression:"'add_queue-modal'"}],staticClass:"btn btn-primary btn-sm",on:{"click":function($event){$event.preventDefault();return _vm.modelAction('add_queue_type')}}},[_c('feather-icon',{attrs:{"icon":"PlusCircleIcon","size":"16"}})],1)])]):_vm._e(),_vm._v(" "),(
        _vm.userData.billing_info.connection_type == 'si_public' ||
        _vm.userData.billing_info.connection_type == 'si_m_b' ||
        _vm.userData.billing_info.connection_type == 'si_private'
      )?_c('div',{staticClass:"col-lg-6 mt-1"},[_c('label',{attrs:{"for":""}},[_vm._v("MAC Address")]),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userData.connection_info.mac_address),expression:"userData.connection_info.mac_address"}],staticClass:"form-control",attrs:{"disabled":"","type":"text","placeholder":"EX: 00:00:00:00:00:00"},domProps:{"value":(_vm.userData.connection_info.mac_address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userData.connection_info, "mac_address", $event.target.value)}}})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"col-lg-6 mt-1"},[_c('label',{attrs:{"for":""}},[_vm._v("Router Comments")]),_vm._v(" "),(
          _vm.userData.billing_info.connection_type == 'si_private_queue' ||
          _vm.userData.billing_info.connection_type == 'si_m_b' ||
          _vm.userData.billing_info.connection_type == 'si_public_queue'
        )?_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userData.connection_info.router_component),expression:"userData.connection_info.router_component"}],staticClass:"form-control",attrs:{"disabled":"","type":"text","placeholder":"Router Comments"},domProps:{"value":(_vm.userData.connection_info.router_component)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userData.connection_info, "router_component", $event.target.value)}}}):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userData.connection_info.router_component),expression:"userData.connection_info.router_component"}],staticClass:"form-control",attrs:{"disabled":"","type":"text","placeholder":"Router Comments"},domProps:{"value":(_vm.userData.connection_info.router_component)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userData.connection_info, "router_component", $event.target.value)}}})]),_vm._v(" "),_c('div',{staticClass:"col-lg-6 mt-1"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"expire_date"}},[_vm._v("Custom Expire Date\n          "),_c('feather-icon',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.v-primary",modifiers:{"hover":true,"v-primary":true}}],attrs:{"title":"User will be expired on this day every month","variant":"outline-primary","icon":"InfoIcon","size":"1.5x"}})],1),_vm._v(" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.userData.connection_info.expire_date),expression:"userData.connection_info.expire_date"}],staticClass:"form-control",attrs:{"id":"expire_date","min":"1","type":"datetime-local","placeholder":"Expire Date"},domProps:{"value":(_vm.userData.connection_info.expire_date)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.userData.connection_info, "expire_date", $event.target.value)}}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }