<template>
  <b-form>
    <div class="row">
      <div class="col-lg-6">
        <label for="">Connection Type: <sup class="text-danger">*</sup></label>
        <select
          @change="change_con_type()"
          v-model="userData.billing_info.connection_type"
          class="form-control"
          name="connection_type"
        >
          <option value="" selected disabled>---Select Type--</option>
          <option v-show="!is_queue" value="di_private">
            Dynamic IP Private (PPPOE NAT)
          </option>
          <option v-show="!is_queue" value="di_public">
            Dynamic IP Public(PPPOE)
          </option>
          <option v-show="!is_queue" value="si_private">
            Static IP Private (PPPOE)
          </option>
          <option v-show="!is_queue" value="si_public">
            Static IP Public (PPPOE)
          </option>
          <option
            v-if="
              userData.billing_info.connection_type == 'si_private_queue' ||
              userData.billing_info.connection_type == 'si_public_queue' ||
              userData.billing_info.connection_type == 'si_m_b'
            "
            value="si_private_queue"
          >
            Static IP Private (Queue)
          </option>
          <option
            v-if="
              userData.billing_info.connection_type == 'si_private_queue' ||
              userData.billing_info.connection_type == 'si_public_queue' ||
              userData.billing_info.connection_type == 'si_m_b'
            "
            value="si_public_queue"
          >
            Static IP Public (Queue)
          </option>
          <option
            v-if="
              userData.billing_info.connection_type == 'si_private_queue' ||
              userData.billing_info.connection_type == 'si_public_queue' ||
              userData.billing_info.connection_type == 'si_m_b'
            "
            value="si_m_b"
          >
            Static IP MAC Bind (ARP)
          </option>
        </select>
      </div>
      <div class="col-lg-6">
        <div v-if="nas_type == 'MikroTik'">
          <label for="">MikroTik <sup class="text-danger">*</sup></label>
          <select
            disabled
            v-if="mikrotiks"
            v-model="userData.connection_info.mikrotik_id"
            class="form-control"
          >
            <option value="">---------Please Select One -------</option>
            <option
              v-for="(mikrotik, midx) in mikrotiks"
              :key="midx"
              :value="mikrotik.id"
            >
              {{ mikrotik.identity + "-" + mikrotik.host }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div
      v-if="
        userData.billing_info.connection_type == 'di_private' ||
        userData.billing_info.connection_type == 'di_public' ||
        userData.billing_info.connection_type == 'si_private' ||
        userData.billing_info.connection_type == 'si_public'
      "
      class="row"
    >
      <div class="col-lg-6 mt-1">
        <label for="">User Name <sup class="text-danger">*</sup></label>
        <div class="from-group">
          <input
            disabled
            v-model="userData.connection_info.username"
            type="text"
            class="form-control"
            placeholder="User Name"
          />
        </div>
      </div>
      <div class="col-lg-6 mt-1">
        <label for="">User Password <sup class="text-danger">*</sup></label>
        <input
          v-model="userData.connection_info.user_password"
          type="text"
          class="form-control"
          placeholder="User Password"
        />
      </div>
    </div>
    <div class="row">
      <div
        v-if="
          userData.billing_info.connection_type == 'di_private' ||
          userData.billing_info.connection_type == 'di_public' ||
          userData.billing_info.connection_type == 'si_private' ||
          userData.billing_info.connection_type == 'si_public'
        "
        class="col-lg-6 mt-1"
      >
        <label for="Service">Service</label>
        <select
          disabled
          v-model="userData.connection_info.service"
          id="Service"
          class="form-control"
        >
          <option value="oVPN">oVPN</option>
          <option value="PPPoE">PPPoE</option>
          <option value="PPtP">PPtP</option>
        </select>
      </div>
      <div
        v-if="
          userData.billing_info.connection_type == 'si_public' ||
          userData.billing_info.connection_type == 'si_private'
        "
        class="col-lg-6 my-1"
      >
        <label for="">Remote Address</label>
        <div class="d-flex">
          <input
            v-model="userData.connection_info.remote_address"
            type="text"
            class="form-control"
            placeholder="Remote Address"
          />
          <button
            @click.prevent="see_remote_address()"
            class="btn btn-primary btn-sm"
          >
            <feather-icon icon="EyeIcon" size="16" />
          </button>
        </div>
      </div>

      <div
        v-if="
          userData.billing_info.connection_type == 'si_private_queue' ||
          userData.billing_info.connection_type == 'si_m_b' ||
          userData.billing_info.connection_type == 'si_public_queue'
        "
        class="col-lg-6"
      >
        <label class="mt-1 mr-2" for="queues">Queues</label>
        <div class="d-flex">
          <select
            @change="change_queue_name"
            class="form-control"
            v-model="userData.connection_info.queue_name"
            id=""
          >
            <!-- let data = this.items.find((x) => x.id === id); -->
            <option value="">---Select--</option>
            <option
              v-for="queue_item in queue_data"
              :key="queue_item.id"
              :value="queue_item.name"
            >
              {{ queue_item.name }}
            </option>
          </select>
          <button
            @click.prevent="modelAction('add_simple_queue')"
            class="btn btn-primary btn-sm"
          >
            <feather-icon icon="PlusCircleIcon" size="16" />
          </button>
        </div>
      </div>
      <div
        v-if="
          userData.billing_info.connection_type == 'si_private_queue' ||
          userData.billing_info.connection_type == 'si_m_b' ||
          userData.billing_info.connection_type == 'si_public_queue'
        "
        class="col-lg-6 my-1"
      >
        <label for="target_address">Target Address</label>
        <div class="d-flex">
          <input
            v-model="userData.connection_info.target_address"
            type="text"
            class="form-control"
            placeholder="Ex: 192.168.0.1"
            id="target_address"
          />
          <button
            @click.prevent="modelAction('target_address')"
            class="btn btn-primary btn-sm"
          >
            <feather-icon icon="EyeIcon" size="16" />
          </button>
        </div>
      </div>
      <div
        v-if="
          userData.billing_info.connection_type == 'si_private_queue' ||
          userData.billing_info.connection_type == 'si_m_b' ||
          userData.billing_info.connection_type == 'si_public_queue'
        "
        class="col-lg-6 my-1"
      >
        <label for="destination">Destination (Interfaces)</label>
        <select
          v-model="userData.connection_info.destination"
          class="form-control"
          id="destination"
        >
          <option value="">---Select Destination (Interfaces)----</option>
          <option v-for="inf in interfaces" :key="inf.id" :value="inf.name">
            {{ inf.name }}
          </option>
        </select>
      </div>
      <div
        v-if="
          userData.billing_info.connection_type == 'si_private_queue' ||
          userData.billing_info.connection_type == 'si_m_b' ||
          userData.billing_info.connection_type == 'si_public_queue'
        "
        class="col-lg-6 my-1"
      >
        <label for="queue_type">Queue Type (PCQ)</label>
        <div class="d-flex">
          <select
            disabled
            v-model="userData.connection_info.queue"
            class="form-control"
            id="queue_type"
          >
            <option value="">---Select Queue Type----</option>
            <option
              v-for="q_t in queue_types"
              :key="q_t.id"
              :value="`${q_t.name}/${q_t.name}`"
            >
              {{ q_t.name }}
            </option>
          </select>
          <button
            @click.prevent="modelAction('add_queue_type')"
            v-b-modal="'add_queue-modal'"
            class="btn btn-primary btn-sm"
          >
            <feather-icon icon="PlusCircleIcon" size="16" />
          </button>
        </div>
      </div>
      <div
        v-if="
          userData.billing_info.connection_type == 'si_public' ||
          userData.billing_info.connection_type == 'si_m_b' ||
          userData.billing_info.connection_type == 'si_private'
        "
        class="col-lg-6 mt-1"
      >
        <!-- userData.billing_info.connection_type == 'si_m_b' || -->
        <label for="">MAC Address</label>
        <input
          disabled
          v-model="userData.connection_info.mac_address"
          type="text"
          class="form-control"
          placeholder="EX: 00:00:00:00:00:00"
        />
      </div>
      <div class="col-lg-6 mt-1">
        <label for="">Router Comments</label>
        <input
          v-if="
            userData.billing_info.connection_type == 'si_private_queue' ||
            userData.billing_info.connection_type == 'si_m_b' ||
            userData.billing_info.connection_type == 'si_public_queue'
          "
          disabled
          v-model="userData.connection_info.router_component"
          type="text"
          class="form-control"
          placeholder="Router Comments"
        />
        <input
          disabled
          v-else
          v-model="userData.connection_info.router_component"
          type="text"
          class="form-control"
          placeholder="Router Comments"
        />
      </div>
      <div class="col-lg-6 mt-1">
        <div class="form-group">
          <label for="expire_date"
            >Custom Expire Date
            <feather-icon
              v-b-tooltip.hover.v-primary
              title="User will be expired on this day every month"
              variant="outline-primary"
              icon="InfoIcon"
              size="1.5x"
            />
          </label>
          <input
            id="expire_date"
            min="1"
            v-model="userData.connection_info.expire_date"
            type="datetime-local"
            class="form-control"
            placeholder="Expire Date"
          />
        </div>
      </div>
    </div>
  </b-form>
</template>

<script>
import axios from "axios";
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
  },

  data() {
    return {
      nas_type: "",
      is_queue: false,
      form: {
        connection_type: "",
      },
    };
  },
  computed: {
    userData() {
      return this.$store.state.isp.customer_edit_data;
    },
    mikrotiks() {
      return this.$store.state.isp.others.mikrotik;
    },
  },
  created() {
    this.get_softwer_system();
    console.log("this.nas_type", this.nas_type);
  },
  methods: {
    get_softwer_system() {
      this.$store.dispatch("spinnerLoading", true);
      axios.get("software-system").then((resp) => {
        this.$store.dispatch("spinnerLoading", false);
        if (resp.data.success) {
          this.nas_type = resp.data.data.nas_type;
        } else {
          this.$error_message(resp.data.message, resp.data.errors);
        }
      });
    },
    getqueue() {},
    getqueueType() {},
    change_con_type() {
      if (
        this.userData.billing_info.connection_type == "si_m_b" ||
        this.userData.billing_info.connection_type == "si_public_queue" ||
        this.userData.billing_info.connection_type == "si_private_queue"
      ) {
        this.userData.connection_info.service =
          this.userData.billing_info.connection_type == "si_m_b"
            ? "ARP"
            : this.userData.billing_info.connection_type == "si_public_queue"
            ? "Public Queue"
            : this.userData.billing_info.connection_type == "si_private_queue"
            ? "Private Queue"
            : this.userData.connection_info.service;
        this.getqueueType();
        this.getqueue();
        this.userData.connection_info.queue_name = this.pkg_info.queue_id;
      }
    },
  },
};
</script>

<style></style>
