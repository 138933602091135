<template>
  <div>
    <!-- Header: Personal Info -->
    <div class="d-flex">
      <feather-icon icon="UserIcon" size="19" />
      <h4 class="mb-0 ml-50">Packages</h4>
    </div>

    <!-- Form: Personal Info Form -->
    <b-form class="mt-1">
      <b-row>
        <b-col cols="12">
          <!-- Field: Packages -->
          <b-row>
            <b-col cols="12" md="6" lg="4">
              <b-form-group label="Packages:" label-for="package">
                <v-select
                  @change="selectPackge"
                  v-model="package_model"
                  :options="select_options_package"
                  :clearable="false"
                  input-id="package"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" lg="4">
              <b-form-group label="Monthly Bill:" label-for="monthly_bill">
                <b-form-input
                  type="number"
                  id="monthly_bill"
                  v-model="userData.billing_info.monthly_bill"
                  @input="change_invoice_cal_item"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" lg="4">
              <b-form-group label="Permanent Discount:" label-for="discount">
                <b-form-input
                  type="number"
                  @input="change_invoice_cal_item"
                  id="discount"
                  v-model="userData.billing_info.discount"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="12" md="6" lg="4">
          <b-row>
            <b-col cols="4">
              <b-form-group label="Collect Bill" label-for="is_home_collect">
                <label :for="'is_home_collect'" class="__switch">
                  <input
                    @change="change_bill_collected"
                    id="is_home_collect"
                    v-model="userData.is_home_collect"
                    type="checkbox"
                    @input="
                      userData.billing_info.is_home_collect =
                        !userData.is_home_collect
                    "
                    :checked="
                      userData.billing_info.is_home_collect == 0 ? true : false
                    "
                  />
                  <span class="__slider" />
                </label>
              </b-form-group>
            </b-col>
            <!-- Field: Address Line 2 -->
            <b-col v-if="userData.is_home_collect" cols="8">
              <b-form-group label="Manager" label-for="Manager">
                <v-select
                  v-model="userData.collect_by"
                  :options="select_options_manager"
                  :clearable="false"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
        <!-- Field: Send Billing Reminder -->
        <b-col cols="12" md="6" lg="4">
          <b-form-group label="Send Billing Reminder:" label-for="postcode">
            <b-form-checkbox-group
              id="contact-options"
              :options="contactOptionsOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- Header: Personal Info -->
      <div class="d-flex mt-2">
        <feather-icon icon="MapPinIcon" size="19" />
        <h4 class="mb-0 ml-50">Payment Info</h4>
      </div>

      <!-- Form: Personal Info Form -->
      <b-row class="mt-1">
        <!-- Field: Address Line 1 -->

        <b-col cols="12" md="6" lg="4">
          <b-form-group
            label="User Payment Type:"
            label-for="user_payment_type"
          >
            <select
              class="form-control"
              v-model="userData.billing_info.user_payment_type"
              id="user_payment_type"
            >
              <option selected value="">
                --------Please Select One ---------
              </option>
              <option value="Prepaid">Prepaid</option>
              <option value="Postpaid">Postpaid</option>
            </select>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6" lg="4">
          <b-form-group label="Bank Payment:" label-for="user_payment_type">
            <select
              v-model="bank_payment"
              class="form-control"
              name="bank_payment"
            >
              <option value="1">Yes</option>
              <option value="0">No</option>
            </select>
          </b-form-group>
        </b-col>
        <b-col v-if="bank_payment == '1'" cols="12" md="6" lg="4">
          <b-form-group label="Bank Name:" label-for="bank_name">
            <b-form-input id="bank_name" v-model="userData.bank_name" />
          </b-form-group>
          <b-form-group
            label="Bank Account Name:"
            label-for="bank_account_name"
          >
            <b-form-input
              id="bank_account_name"
              v-model="userData.bank_account_name"
            />
          </b-form-group>
          <b-form-group
            label="Bank Account Number:"
            label-for="bank_account_number"
          >
            <b-form-input
              id="bank_account_number"
              v-model="userData.bank_account_number"
            />
          </b-form-group>
          <b-form-group label="Branch Name:" label-for="bank_account_branch">
            <b-form-input
              id="bank_account_branch"
              v-model="userData.bank_account_branch"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ref } from "@vue/composition-api";
import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
  },

  data() {
    return {
      select_options_package: [],
      bank_payment: 0,
      package_model: "",
      select_options_manager: [],
      contactOptionsOptions: [
        { text: "Email", value: "male" },
        { text: "Message", value: "message" },
        { text: "Phone", value: "Phone" },
      ],
    };
  },
  computed: {
    userData() {
      var package_data = this.$store.state.isp.others.package;
      package_data.forEach((element) => {
        const obj = {
          code: element.id,
          label: element.name,
        };
        this.select_options_package.push(obj);
        if (
          element.id ==
          this.$store.state.isp.customer_edit_data.billing_info.package_id
        ) {
          const package_model = {
            code: element.id,
            label: element.name,
          };
          this.package_model = package_model;
        }
      });
      return this.$store.state.isp.customer_edit_data;
    },
    user_infos() {
      return this.$store.state.isp.user.info;
    },

    mikrotiks() {
      return this.$store.state.isp.others.mikrotik;
    },
  },

  methods: {
    selectPackge(e) {
      const pkg = this.InPackage.find(
        (ele) => ele.id.toString() === e.target.value
      );
      this.userData.is_queue = pkg.queue_id ? true : false;
      this.userData.form.monthly_bill = pkg.price;
      this.userData.form.mikrotik = pkg.mikrotik_id;
      this.userData.pkg_info = pkg;
      this.change_invoice_cal_item();
    },

    change_invoice_cal_item() {
      const connection_fee = parseInt(
        this.userData.connection_fee ? this.userData.connection_fee : 0
      );
      const monthly_bill = parseInt(
        this.userData.monthly_bill ? this.userData.monthly_bill : 0
      );
      const discount = parseInt(
        this.userData.discount ? this.userData.discount : 0
      );
      this.userData.total_invoice_price =
        connection_fee + monthly_bill - discount;
    },
    change_bill_collected() {
      if (this.billing_info.is_home_collect == 0) {
        this.select_options_manager = [];
        this.$store.dispatch("spinnerLoading", true);
        this.axios.get("user").then((resp) => {
          this.$store.dispatch("spinnerLoading", false);
          if (resp.data.success) {
            resp.data.data.forEach((ele) => {
              const obj = {
                code: ele.id,
                label: `${ele.name}`,
              };
              this.select_options_manager.push(obj);
            });
            //  console.log("Users " + JSON.stringify(this.users))
          } else {
            if (resp.data.code == 403) {
              this.permissionError = resp.data.message;
              this.$error_message(resp.data.message, resp.data.errors);
            } else {
              this.$error_message(resp.data.message, resp.data.errors);
            }
          }
          this.select_options_queue.push(obj);
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
