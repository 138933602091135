<template>
  <div>
    <!-- Header: Permanent address -->
    <div class="d-flex">
      <feather-icon icon="UserIcon" size="19" />
      <h4 class="mb-0 ml-50">Address</h4>
    </div>

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>
        <!-- Field: Username -->
        <b-col cols="12" md="4">
          <b-form-group label="House No:" label-for="house_number_one">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon size="16" icon="HomeIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="house_number_one"
                v-model="userData.userdetails.house_number_one"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- Field: Full Name -->
        <b-col cols="12" md="4">
          <b-form-group label="Road No:" label-for="road_number_one">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon size="16" icon="AlignJustifyIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="road_number_one"
                v-model="userData.userdetails.road_number_one"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col cols="12" md="4">
          <b-form-group label="Float No:" label-for="float_number_one">
            <b-form-input
              id="float_number_one"
              v-model="userData.userdetails.float_number_one"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Area: -->
        <b-col cols="12" md="4">
          <b-form-group label="Area:" label-for="area_one">
            <b-form-input
              id="area_one"
              v-model="userData.userdetails.area_one"
            />
          </b-form-group>
        </b-col>

        <!-- Field: post_code_one: -->
        <b-col cols="12" md="4">
          <b-form-group label="Post Code:" label-for="post_code_one">
            <b-form-input
              id="post_code_one"
              v-model="userData.userdetails.post_code_one"
            />
          </b-form-group>
        </b-col>
        <!-- Field: District: -->
        <b-col cols="12" md="4">
          <b-form-group label="District:" label-for="district_one">
            <select
              v-if="districts && districts.length > 0"
              v-model="userData.userdetails.district_one"
              class="form-control"
              name="district"
              @change="districtWiseCity($event)"
            >
              <option value="" selected disabled>
                ------Please Select One -----
              </option>
              <option
                v-for="(district, didx) in districts"
                :key="didx"
                :value="district.id"
              >
                {{ district.name }}
              </option>
            </select>
            <router-link v-else :to="{ name: 'district' }"
              >Please Create District</router-link
            >
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Header: Personal Info -->
      <div class="d-flex">
        <feather-icon icon="UserIcon" size="19" />
        <h4 class="mb-0 ml-50">Permanent Address</h4>
        <b-form-checkbox v-model="sameAddress" name="check-button" switch>
          <small>As Like Current Address</small>
        </b-form-checkbox>
      </div>

      <b-row>
        <!-- Field: Username -->
        <b-col cols="12" md="4">
          <b-form-group label="House No:" label-for="house_number_one">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon size="16" icon="HomeIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="house_number_one"
                v-model="userData.userdetails.house_number_one"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <!-- Field: Full Name -->
        <b-col cols="12" md="4">
          <b-form-group label="Road No:" label-for="road_number_one">
            <b-form-input
              id="road_number_one"
              v-model="userData.userdetails.road_number_one"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Email -->
        <b-col cols="12" md="4">
          <b-form-group label="Float No:" label-for="float_number_one">
            <b-form-input
              id="float_number_one"
              v-model="userData.userdetails.float_number_one"
            />
          </b-form-group>
        </b-col>

        <!-- Field: Area: -->
        <b-col cols="12" md="4">
          <b-form-group label="Area:" label-for="area_one">
            <b-form-input
              id="area_one"
              v-model="userData.userdetails.area_one"
            />
          </b-form-group>
        </b-col>

        <!-- Field: post_code_one: -->
        <b-col cols="12" md="4">
          <b-form-group label="Post Code:" label-for="post_code_one">
            <b-form-input
              id="post_code_one"
              v-model="userData.userdetails.post_code_one"
            />
          </b-form-group>
        </b-col>
        <!-- Field: District: -->
        <b-col cols="12" md="4">
          <b-form-group label="District:" label-for="district_one">
            <select
              v-if="districts && districts.length > 0"
              v-model="userData.userdetails.district_one"
              class="form-control"
              name="district"
              @change="districtWiseCity($event)"
            >
              <option value="" selected disabled>
                ------Please Select One -----
              </option>
              <option
                v-for="(district, didx) in districts"
                :key="didx"
                :value="district.id"
              >
                {{ district.name }}
              </option>
            </select>
            <router-link v-else :to="{ name: 'district' }"
              >Please Create District</router-link
            >
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import { ref } from "@vue/composition-api";
// import useUsersList from '../users-list/useUsersList'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
  },
  computed: {
    userData() {
      return this.$store.state.isp.customer_edit_data;
    },
    districts() {
      return this.$store.state.isp.others.districts;
    },
  },
  watch: {
    sameAddress(value) {
      if (value) {
        this.userData.house_number_two = this.userData.house_number_one;
        this.userData.area_two = this.userData.area_one;
        this.userData.float_number_two = this.userData.float_number_one;
        this.userData.road_number_two = this.userData.road_number_one;
        this.userData.post_code_two = this.userData.post_code_one;
      } else {
        this.userData.house_number_two = "";
        this.userData.area_two = "";
        this.userData.float_number_two = "";
        this.userData.road_number_two = "";
        this.userData.post_code_two = "";
      }
      console.log(value);
    },
  },
  methods: {
    districtWiseCity(e) {
      this.axios.get(`upazila/district/${e.target.value}`).then((resp) => {
        if (resp.data.success) {
          this.upazila_one = resp.data.upazila;
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },
    districtWiseCityTwo(e) {
      this.axios.get(`upazila/district/${e.target.value}`).then((resp) => {
        if (resp.data.success) {
          this.upazila_two = resp.data.upazila;
        } else {
          this.$error_message(resp.data.message);
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
