<template>
  <div>
    <!-- Header: Personal Info -->
    <div class="d-flex">
      <feather-icon icon="UserIcon" size="19" />
      <h4 class="mb-0 ml-50">Packages</h4>
    </div>

    <!-- Form: Personal Info Form -->
    <b-form class="mt-1">
      <b-row>
        <b-col cols="12">
          <!-- Field: Packages -->
          <b-row>
            <b-col cols="12" md="6" lg="4">
              <b-form-group label="Zone:" label-for="zone">
                <select
                  v-if="zone && zone.length > 0"
                  v-model="userData.zone_id"
                  name="zone"
                  class="form-control"
                >
                  <option value="" disabled>
                    --------Please Select One ---------
                  </option>
                  <option v-for="zon in zone" :key="zon.id" :value="zon.id">
                    {{ zon.name }}
                  </option>
                </select>
                <router-link v-else :to="{ name: 'zone' }"
                  >Please Create Zone</router-link
                >
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" lg="4">
              <b-form-group label="Expire Date:" label-for="expire_date">
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend class="days_text" is-text>
                    Days
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="userData.connection_info.expire_date"
                    min="1"
                    id="expire_date"
                    type="number"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" lg="4">
              <b-form-group label="Ref Number:" label-for="expire_date">
                <div class="d-flex">
                  <input
                    :disabled="!edit_ref"
                    v-model="userData.billing_info.user_reference_number"
                    type="text"
                    class="form-control"
                    placeholder="Refrence Number"
                  />
                  <button
                    v-b-tooltip.top.v-danger
                    title="Make sure youn have to change?"
                    @click.prevent="edit_ref = !edit_ref"
                    class="btn btn-primary btn-sm"
                  >
                    <feather-icon
                      :icon="!edit_ref ? 'Edit3Icon' : 'ArrowRightIcon'"
                      size="16"
                    />
                  </button>
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" lg="4">
              <b-form-group
                label="Connection Date:"
                label-for="connection_date"
              >
                <b-form-input
                  v-model="userData.billing_info.connection_date"
                  id="connection_date"
                  type="date"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6" lg="4">
              <b-form-group
                label="Registration Date:"
                label-for="registration_date"
              >
                <b-form-input
                  v-model="userData.billing_info.registration_date"
                  id="registration_date"
                  type="date"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ref } from "@vue/composition-api";
import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    flatPickr,
    BFormInput,
    vSelect,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
  },
  data() {
    return {
      edit_ref: false,
    };
  },

  computed: {
    userData() {
      return this.$store.state.isp.customer_edit_data;
    },
    zone() {
      return this.$store.state.isp.others.zone;
    },
  },
  methods: {
    change_invoice_cal_item() {
      const connection_fee = parseInt(
        this.userData.connection_fee ? this.userData.connection_fee : 0
      );
      const monthly_bill = parseInt(
        this.userData.monthly_bill ? this.userData.monthly_bill : 0
      );
      const discount = parseInt(
        this.userData.discount ? this.userData.discount : 0
      );
      this.userData.total_invoice_price =
        connection_fee + monthly_bill - discount;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.days_text .input-group-text {
  border-right: 1px solid !important;
}
</style>
